














































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsOverlayCard from '@/components/VsOverlayCard/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { CampaignStatusEnum, ICampaign } from '@/api/consoleApi/dto/campaigns.dto'
import { updateCampaign } from '@/api/consoleApi/campaigns'
import DomainNameModal from '@/modules/campaigns/components/DomainNameModal/Index.vue'
import { copyToClipboard } from '@/utils/copyToClipboard'
import axios from 'axios'
import { UserModule } from '@/store/modules/user'
import {
    uploadAttachment,
    getAttachmentsByEmailId,
    deleteAttachmentById,
} from '@/api/consoleApi/contents/attachments'
import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import { setAutomationEntitySettings } from '@/api/console'
import { updateWorkflowMessage } from '@/api/consoleApi/workflows'

@Component({
    name: 'VsEmailAdvancedCard',
    components: {
        VsLoader,
        VsCollapseCard,
        VsOverlayCard,
        DomainNameModal,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) campaign!: ICampaign
    @Prop({ required: true, default: '' }) tabOpen!: string
    @Prop({ required: false, default: 'campaign' }) contentMode!: string
    @Prop({ required: false, default: '' }) messageId!: string
    @Prop({ required: false, default: '' }) workflowMessageId!: any
    @Prop({ required: false, default: true, type: Boolean }) isEditable!: boolean
    @Prop({ required: false, default: false, type: Boolean }) showEditButton!: boolean
    private loading = false
    private tempActiveAnalytics = false
    private tempUtmSource = ''
    private tempUtmMedium = ''
    private tempUtmCampaign = ''
    private tempActiveGoalTracking = false
    private tempActiveEcommerceTracking = false
    private tempTtlEcommerceTracking = '5'
    private attachments: any[] = []

    $refs: any

    openTab () {
        this.$emit('open-tab', 'advanced')
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get utmSourceConfig () {
        return get(AppModule.consoleConf, 'googleAnalyticsSettings.source', '')
    }

    get utmMediumConfig () {
        return get(AppModule.consoleConf, 'googleAnalyticsSettings.medium', '')
    }

    get campaignId () {
        return this.$route.params.campaignId || ''
    }

    get user () {
        return UserModule.user
    }

    get attachmentRuleValue () {
        return this.user.configuration.rules.attachmentsBytes
    }

    get canEplus () {
        return this.user.configuration.rules.eplusLicense > 0 || this.user.configuration.rules.eplusLicense === -1
    }

    get totalAttachmentsSize () {
        return this.attachments.reduce((accumulator, object) => {
            return accumulator + object.file_size
        }, 0)
    }

    get googleAnalyticsTip () {
        const utmSource = this.$t('campaigns.editCampaign.sections.advanced.analyticsUtmSource') + ': ' + this.campaign.settings.google_analytics_settings?.utm_source || this.utmSourceConfig
        const utmMedium = this.$t('campaigns.editCampaign.sections.advanced.analyticsUtmMedium') + ': ' + this.campaign.settings.google_analytics_settings?.utm_medium || this.utmSourceConfig
        const utmCampaign = this.$t('campaigns.editCampaign.sections.advanced.analyticsUtmCampaign') + ': ' + this.campaign.settings.google_analytics_settings?.utm_campaign || this.utmSourceConfig
        return `${utmSource}<br/>${utmMedium}<br/>${utmCampaign}`
    }

    get attachmentsTip () {
        let text = ''
        this.attachments.forEach(att => {
            text += att.file_name + '<br>'
        })
        return text
    }

    get consoleId () {
        return get(AppModule.consoleConf, 'consoleId', '')
    }

    get consoleUserId () {
        return (UserModule.consoleUserId + 1000).toString(36)
    }

    get code () {
        const script = 'script'
        return `
<!-- Email Automation Tracker - Start -->
<${script} type="text/javascript" charset="utf-8">var $atmTRK={'settings':{'cid':'${this.consoleId}','uid':'{{${this.consoleUserId}}}'}};(function(){var st=document.createElement('script');st.type='text/javascript';st.async=true;st.defer=true;st.src=('https:'==document.location.protocol?'https://trk.mktaut.com':'http://trk.mktaut.com')+'/assets/goaltracking.js';var s=document.getElementsByTagName('script')[0];s.parentNode.insertBefore(st,s);})();</${script}>
<!-- Email Automation Tracker - End -->`
    }

    private openTabAdvanced () {
        this.tempActiveAnalytics = this.campaign.settings.google_analytics_settings !== null
        this.tempUtmSource = this.campaign.settings.google_analytics_settings?.utm_source || this.utmSourceConfig
        this.tempUtmMedium = this.campaign.settings.google_analytics_settings?.utm_medium || this.utmMediumConfig
        this.tempUtmCampaign = this.campaign.settings.google_analytics_settings?.utm_campaign || this.campaign.name
        this.tempActiveGoalTracking = this.campaign.settings.goal_tracking
        this.tempActiveEcommerceTracking = this.campaign.settings.ecommerce_tracking.active
        this.tempTtlEcommerceTracking = this.campaign.settings.ecommerce_tracking.ttl
        this.openTab()
    }

    private async saveAdvanced () {
        if (!(await this.$refs.advancedForm.validate())) {
            return
        }
        if (this.contentMode === 'campaign') {
            await this.updateCampaignOptions()
        } else if (this.contentMode === 'automation') {
            await this.updateAutomationOptions()
        } else {
            this.$emit('advanced-saved')
            this.$emit('open-tab', '')
        }
    }

    private async updateCampaignOptions () {
        try {
            await updateCampaign(
                this.campaign.id,
                {
                    settings: {
                        goal_tracking: this.tempActiveGoalTracking,
                        google_analytics_settings: this.getAnalyticsSettingsToSave(),
                        ecommerce_tracking: {
                            active: this.tempActiveEcommerceTracking,
                            ttl: this.tempTtlEcommerceTracking,
                        },
                    },
                },
            )

            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.advanced.advancedSaved'),
                aspect: VsToastAspectEnum.success,
            })

            this.$emit('advanced-saved')
            this.$emit('open-tab', '')
        } catch (e) {
            console.log(e)
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    if (e.response?.data.message) {
                        const errors: any = {}
                        for (const errorName in e.response.data.message) {
                            const errorMessage = e.response.data.message[errorName]
                            if (errorMessage.length > 0 && errorMessage[0].includes('must be valid domain name')) {
                                errors[errorName] = [this.$t('campaigns.editCampaign.sections.advanced.advancedSavedInvalidDomainError')]
                            }
                        }
                        this.$refs.advancedForm.setErrors(errors)
                    }
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.advanced.advancedSavedError'),
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async updateAutomationOptions () {
        try {
            await updateWorkflowMessage(
                this.campaign.id,
                this.workflowMessageId,
                {
                    settings: {
                        tracking: {
                            ecommerce: {
                                active: this.tempActiveEcommerceTracking,
                                ttl: this.tempTtlEcommerceTracking,
                            },
                        },
                    },
                },
            )
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.advanced.advancedSaved'),
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('advanced-saved')
            this.$emit('open-tab', '')
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.advanced.advancedSavedError'),
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private getAnalyticsSettingsToSave () {
        const analyticsSettings = {
            utm_source: this.tempUtmSource,
            utm_medium: this.tempUtmMedium,
            utm_campaign: this.tempUtmCampaign,
        } as any
        if (!this.tempActiveAnalytics) return {}
        return analyticsSettings
    }

    private async openConfirmDraft () {
        this.$emit('open-confirm-draft')
    }

    private copyToClipboard () {
        try {
            copyToClipboard(this.code)
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.sections.advanced.codeCopied'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.sections.advanced.codeCopiedError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private async addAttachment () {
        this.loading = true
        const uploadedFile = this.$refs.file.files[0]
        if (!uploadedFile) {
            return
        }
        try {
            await uploadAttachment(this.messageId, uploadedFile)
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.sections.advanced.attachmentSaved'),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            await this.getAttachments()
        } catch (e) {
            console.log(e)
            const heading = this.$t('campaigns.editCampaign.sections.advanced.attachmentSavedError')
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 413) {
                    message = this.$t('campaigns.editCampaign.sections.advanced.attachmentSavedSizeError').toString()
                }
                if (e.response?.status === 403) {
                    message = this.$t('campaigns.editCampaign.sections.advanced.attachmentSavedLimitError').toString()
                }
            }
            this.$root.$vsToast({
                heading,
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.$refs.file.value = ''
        if (this.$refs.file.value) {
            this.$refs.file.type = 'text'
            this.$refs.file.type = 'file'
        }
        this.loading = false
    }

    @Watch('messageId', { immediate: true })
    private async getAttachments () {
        try {
            const resp = await getAttachmentsByEmailId(this.messageId)
            this.attachments = resp.data.data
        } catch (e) {
            console.log(e)
            this.attachments = []
        }
    }

    private async deleteAttachment (attachmentId: any) {
        this.loading = true
        try {
            await deleteAttachmentById(this.messageId, attachmentId)
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.sections.advanced.attachmentDeleted'),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            await this.getAttachments()
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.sections.advanced.attachmentDeletedError'),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            console.log(e)
        }
        this.loading = false
    }
}
