import { serviceConsole } from '@/utils/request'
import { CancelToken } from 'axios'

export const createShortUrl = (smsId: string, url: string) =>
    serviceConsole.post(
        `/app/user/sms/shorturlAjax/${smsId}`,
        {
            url,
        },
        {
            // signal,
        },
    )

export const getClicksCountCampaigns = (campaignId: string, cancelToken?: CancelToken) =>
    serviceConsole.get(`/app/user/campaign/getClicksCount/${campaignId}`, {
        cancelToken,
    })

export const getFooters = (cancelToken?: CancelToken) =>
    serviceConsole.get(
        `/app/user/addressbooks/getFooters`,
        {
            cancelToken,
        },
    )

export const agencyOpenTicket = (data: { message: string, subject: string}, cancelToken?: CancelToken) =>
    serviceConsole.post(
        `/app/user/support/agency_open_ticket`,
        data,
        {
            cancelToken,
        },
    )

export const createModel = (messageId: any, data: { title: string, description: string}, cancelToken?: CancelToken) =>
    serviceConsole.post(
        `/app/user/email/createModel/${messageId}`,
        data,
        {
            cancelToken,
        },
    )

export const search = (data: any, cancelToken?: CancelToken) =>
    serviceConsole.post(
        `/app/user/search/`,
        data,
        {
            cancelToken,
        },
    )

export const getConsoleConfig = (cancelToken?: CancelToken) =>
    serviceConsole.get(
        `/pub/config`,
        {
            cancelToken,
        },
    )

export const getTokenForDragoUser = (cancelToken?: CancelToken) =>
    serviceConsole.post(
        `/app/user/drago/getTokenV2`,
        {
            userId: 'user',
        },
        {
            cancelToken,
            timeout: 30000,
        },
    )

export const getTokenForDragoTemplater = (cancelToken?: CancelToken) =>
    serviceConsole.post(
        `/app/user/drago/getTokenV2`,
        {
            userId: 'templater',
        },
        {
            cancelToken,
            timeout: 30000,
        },
    )

export const estimateCampaignContacts = (campaignId: any, cancelToken?: CancelToken) =>
    serviceConsole.get(
        `/app/user/campaign/estimateV2/${campaignId}`,
        {
            cancelToken,
            timeout: 30000,
        },
    )

export const getAutomationEntity = (entityId: any, cancelToken?: CancelToken) =>
    serviceConsole.get(
        `/app/user/automation/get_entity_by_id/${entityId}`,
        {
            cancelToken,
            timeout: 30000,
        },
    )

export const setAutomationEntitySettings = (entityId: any, data: any, cancelToken?: CancelToken) =>
    serviceConsole.post(
        `/app/user/automation/setEntitySettings/${entityId}`,
        data,
        {
            cancelToken,
            timeout: 30000,
        },
    )

export const generateArchiveUrl = (data: any, cancelToken?: CancelToken) =>
    serviceConsole.post(
        `/app/user/campaigns/archives`,
        data,
        {
            cancelToken,
            timeout: 30000,
        },
    )
