import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'
// import { LayoutsEnum } from '@/layouts/types'

const workflowsRouter: RouteConfig[] = [
    {
        path: '/workflows',
        redirect: { name: 'workflows' },
        name: 'workflowsIndex',
        component: VsLayouts,
        children: [
            {
                path: '/',
                name: 'workflows',
                component: () => import(/* webpackChunkName: "workflows" */ '@/modules/workflows/views/index.vue'),
            },
            {
                path: ':workflowId/edit',
                name: 'editWorkflow',
                component: () => import(/* webpackChunkName: "edit-workflow" */ '../views/id/edit.vue'),
            },
            {
                path: ':workflowId/statistics',
                name: 'workflowStatistics',
                component: () => import(/* webpackChunkName: "workflow-statistics" */ '../views/id/statistics.vue'),
            },
        ],
    },
]

export default workflowsRouter
