import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'

/* Router modules */
import accountRouter from '@/modules/account/routes'
import activationRouter from '@/modules/activation/routes'
import shopRouter from '@/modules/shop/routes'
import loginRouter from '@/modules/login/routes'
import signupRouter from '@/modules/signup/routes'
import leadplusRouter from '@/modules/leadplus/routes'
import dashboardRouter from '@/modules/dashboard/routes'
import campaignsRouter from '@/modules/campaigns/routes'
import listsRouter from '@/modules/lists/routes'
import landingpagesRouter from '@/modules/landingpages/routes'
import developersRouter from '@/modules/developers/routes'
import sendersRouter from '@/modules/senders/routes'
import settingsRouter from '@/modules/settings/routes'
import mockRouter from '@/modules/mock/routes'
import managerRouter from '@/modules/manager/routes'
import emailEditorRouter from '@/modules/emailEditor/routes'
import whatsappRouter from '@/modules/whatsapp/routes'
import automationsRouter from '@/modules/automations/routes'
import workflowsRouter from '@/modules/workflows/routes'
import smsRouter from '@/modules/sms/routes'
import subscriberAreaRouter from '@/modules/subscriberArea/routes'

// se scommentata questa parte zittisce l'errore al logout login di vue router
// const routerPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(error=> console.log(error))
// }

const asyncModulesRoutes = [
    ...activationRouter,
    ...signupRouter,
    ...accountRouter,
    ...shopRouter,
    ...campaignsRouter,
    ...listsRouter,
    ...dashboardRouter,
    ...landingpagesRouter,
    ...settingsRouter,
    ...developersRouter,
    ...sendersRouter,
    ...emailEditorRouter,
    ...whatsappRouter,
    ...managerRouter,
    ...leadplusRouter,
    ...smsRouter,
    ...subscriberAreaRouter,
]

if (process.env.VUE_APP_LOAD_MOCK_MODULE === 'true') {
    asyncModulesRoutes.push(...mockRouter)
    asyncModulesRoutes.push(...automationsRouter)
    asyncModulesRoutes.push(...workflowsRouter)
}

Vue.use(Router)

/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
export const constantRoutes: RouteConfig[] = [
    {
        path: '/',
        redirect: '/dashboard',
        meta: { hidden: true },
    },
    ...loginRouter,
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/
export const asyncRoutes: RouteConfig[] = [
    ...asyncModulesRoutes,
    {
        path: '*',
        redirect: '/dashboard',
        // redirect: '/404',
        meta: { hidden: true },
    },
]

const createRouter = () => new Router({
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    base: process.env.VUE_APP_ROUTE_BASE_PATH ? process.env.VUE_APP_ROUTE_BASE_PATH : '',
    routes: [
        ...constantRoutes,
        ...asyncRoutes,
    ],
})

const router = createRouter()

export function resetRouter () {
    const newRouter = createRouter();
    (router as any).matcher = (newRouter as any).matcher
}

export default router
